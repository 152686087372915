import { useRouter } from "next/router"
import { extractNumberFromQueryString } from "common/lib/queryString"
import { usePageProps } from "common/hooks/data/usePageProps"
import { NewslettersPageProps } from "pages/newsletters/index.page"
import { GroupItem } from "common/types/graphql"
import { useMemo } from "react"

/**
 * Returns newsletter groups from the page props and the promoted newsletter.
 */
export const useNewsletterGroups = () => {
  const { query } = useRouter()
  const { groups } = usePageProps<NewslettersPageProps>()

  return useMemo(() => {
    if (!groups.length) {
      return { groups, promotedNewsletter: undefined }
    }

    /*
     * Identify the promoted newsletter.
     *
     * The promoted newsletter (hero type) is a special slot rendered if a group item is promoted.
     *
     * The query string `promoted` can override this to select the promoted newsletter.f
     *
     * If no promoted newsletter exists, the hero slot will not be rendered.
     */
    const promotedIdQueryString = extractNumberFromQueryString(query.promoted)

    const groupItems = groups.flatMap((group) => group.groupItems)
    const promotedItem: GroupItem | undefined = groupItems.find((item) => item.promoted)
    const promotedNewsletter = promotedItem
      ? groupItems.find((item) => item.id === promotedIdQueryString) || promotedItem
      : undefined

    return { groups, promotedNewsletter }
  }, [groups, query])
}
