import { NewsletterSubscriber } from "modules/newsletters/components/NewsletterSubscriber"
import { useNewsletterGroups } from "modules/newsletters/hooks/useNewsletterGroups"

/**
 * Component that will render newsletter groups as a hero and a list of newsletter cards.
 * This component contains the logic to pluck out the promoted newsletter from the given list of groups,
 * rendering on as the hero of the first group and the rest as newsletter cards.
 */
export const Groups = () => {
  const { groups, promotedNewsletter } = useNewsletterGroups()

  if (!groups.length) {
    return null
  }

  return (
    <main>
      {groups.map(({ name, groupItems }, index) => {
        const isFirstGroup = index === 0
        const regularNewsletters = groupItems.filter(
          (groupItem) => groupItem.id !== promotedNewsletter?.id,
        )

        return (
          <section key={name}>
            <h3 className="mb-3 mt-16 font-sans-heading text-lg font-bold">{name}</h3>

            {isFirstGroup && promotedNewsletter && (
              <NewsletterSubscriber type="hero" groupItem={promotedNewsletter} />
            )}

            <div className="my-5 grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-8">
              {regularNewsletters.map((groupItem) => (
                <NewsletterSubscriber key={groupItem.id} type="card" groupItem={groupItem} />
              ))}
            </div>
          </section>
        )
      })}
    </main>
  )
}
