import { Button, Style } from "modules/newsletters/components/Button"
import { ImgixImage } from "common/components/ImgixImage"
import { NewsletterProps } from "modules/newsletters/types"

const INDIGO_50_HEX = "#f1f2f8"

/**
 * Hero image component, which displays the newsletter image in different sizes.
 * In case there is no image src, the component will return null.
 */
const HeroImage = ({ src }: { src?: string }) => {
  if (!src) {
    return null
  }

  return (
    <div className="relative ml-3 mt-5 grow 2xl:mr-4">
      <ImgixImage
        src={src}
        className="rounded-br-md object-cover object-left-top"
        placeholderColor={INDIGO_50_HEX}
      />
    </div>
  )
}

/**
 * Hero component, which represents the promoted newsletter, being placed at the top of
 * the newsletter subscription page.
 */
export const Hero = ({
  name,
  frequency,
  description,
  imageSrc,
  previewUrl,
  buttonLabels,
  selected = false,
  loading,
  onSelect,
}: NewsletterProps) => {
  return (
    <div className="flex rounded-md bg-indigo-50 2xl:gap-4" data-testid="newsletter-hero">
      <div className="flex w-7/12 flex-col gap-y-3 py-5 pl-4 lg:justify-center lg:gap-y-4 lg:pl-24">
        <div className="font-sans text-lg font-bold text-black md:text-xl lg:text-3xl">{name}</div>
        <div className="text-sm font-medium text-indigo-600 md:text-base lg:text-xl">
          {frequency}
        </div>
        <div className="font-serif text-2xs font-normal leading-relaxed md:text-base lg:text-lg lg:leading-normal">
          {description}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4">
          <Button
            style={loading ? Style.Loading : selected ? Style.Selected : Style.Default}
            onClick={onSelect}
            trackingClassName="newsletter-hero-select-button"
            fullWidthTablets
          >
            {selected ? buttonLabels.selected : buttonLabels.select}
          </Button>
          {previewUrl && (
            <Button
              href={previewUrl}
              style={Style.Preview}
              trackingClassName="newsletter-hero-preview-button"
              fullWidthTablets
            >
              {buttonLabels.preview}
            </Button>
          )}
        </div>
      </div>

      <HeroImage src={imageSrc} />
    </div>
  )
}
